import React from 'react';
import Layout from './components/Layout';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Participant from './components/Participant';
import SignUp from './signUp/components/SignUp';
import SignUpOld from './Register/SignUp';
import { AuthProvider } from './Security/Auth';
import { SignInPage } from './Security/SignInPage';
import { SignOutPage } from './Security/SignOutPage';
import { AdminPanel } from './Admin/AdminPanel';
import SignUpList from './signUp/components/SignUpList';
import SignUpForm from './signUp/components/SignUpForm';
import SignUpDummy from './signUp/components/SignUpDummy';
import SignUpPaymentFinished from './signUp/components/SignUpPaymentFinished';
import SignUpFinished from './signUp/components/SignUpFinished';
import SignUpTerms from './signUp/components/SignUpTerms';
import SignUpPrivacyPolicy from './signUp/components/SignUpPrivacyPolicy';

import './Core/i18n/i18n';
import SignUpFestivalTerms from './signUp/components/SignUpFestivalTerms';

// import './custom.css'; sprawdzic czy to ma być
// import './app.css'; sprawdzić czy to ma być

const App: React.FC = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route
                            path="/paymentFinished"
                            element={
                                <SignUp>
                                    <SignUpPaymentFinished></SignUpPaymentFinished>
                                </SignUp>
                            }
                        />
                        <Route
                            path="/signupFinished"
                            element={
                                <SignUp>
                                    <SignUpFinished></SignUpFinished>
                                </SignUp>
                            }
                        />
                        <Route
                            path="/imladris"
                            element={<Navigate to="/pl/signup/participant" />}
                        />
                        <Route
                            path="/media"
                            element={<Navigate to="/pl/signup/media" />}
                        />
                        <Route
                            path="/staff"
                            element={<Navigate to="/pl/signup/staff" />}
                        />
                        <Route
                            path="/exhibitor"
                            element={<Navigate to="/pl/signup/exhibitor" />}
                        />
                        <Route
                            path="/program2"
                            element={<Navigate to="/pl/signup/program2" />}
                        />
                        <Route
                            path="/program"
                            element={<Navigate to="/pl/signup/program" />}
                        />
                        <Route
                            path="/organizer"
                            element={<Navigate to="/pl/signup/organizer" />}
                        />
                        <Route
                            path="/guest"
                            element={<Navigate to="/pl/signup/guest" />}
                        />
                        <Route path="/" element={<Navigate to="/imladris" />} />
                        <Route
                            path="/privacyPolicy"
                            element={
                                <SignUp>
                                    <SignUpPrivacyPolicy></SignUpPrivacyPolicy>
                                </SignUp>
                            }
                        />
                        <Route
                            path="/signupTerms"
                            element={
                                <SignUp>
                                    <SignUpTerms></SignUpTerms>
                                </SignUp>
                            }
                        />
                        <Route
                            path="/test"
                            element={<Navigate to="/pl/signup/participant" />}
                        />
                        <Route
                            path="/signup/participant"
                            element={<Navigate to="/pl/signup/participant" />}
                        />
                        <Route
                            path=":lang/signup/:membershipType"
                            element={
                                <SignUp>
                                    <SignUpForm></SignUpForm>
                                </SignUp>
                            }
                        />
                        <Route
                            path="/"
                            element={<Navigate to="pl/signup/participant" />}
                        />
                        <Route path="/fetch-data" element={<Participant />} />
                        <Route path="/admin" element={<AdminPanel />} />
                        <Route
                            path="/signin"
                            element={<SignInPage action="signin" />}
                        />
                        <Route
                            path="/signin-callback"
                            element={<SignInPage action="signin-callback" />}
                        />
                        <Route
                            path="/signout"
                            element={() => <SignOutPage action="signout" />}
                        />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default App;

/*
<Route
                            path="/"
                            element={
                                <SignUp>
                                    <SignUpDummy></SignUpDummy>
                                </SignUp>
                            }
                        />
*/

/*
<Route
    path="/"
    element={<Navigate to="pl/signup/participant"/>}
/>
*/

/*
 <Route
                            path="/signup/participant"
                            element={<Navigate to="/pl/signup/participant" />}
                        />
                        <Route
                            path=":lang/signup/:membershipType"
                            element={
                                <SignUp>
                                    <SignUpForm></SignUpForm>
                                </SignUp>
                            }
                        />

                        <Route
                            path="/test"
                            element={<Navigate to="/pl/signup/participant" />}
                        />

                        <Route
                            path="/"
                            element={
                                <SignUp>
                                    <SignUpDummy></SignUpDummy>
                                </SignUp>
                            }
                        />
*/
