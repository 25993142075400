//TODO typ merch zwrot
import { MerchOptions } from '../components/SignUpForm';

/*
const MapMerchItemToRest = (merchName: string, merchValue: string) {

}
*/

export const MapMerchToRest = (merch: MerchOptions): string => {
    const mappedMerch = Object.keys(merch)
        .filter((key) => !key.includes('Options') && !key.includes('Count'))
        .reduce((mappedMerch, merchName) => {
            if (!merch[`${merchName}`]) {
                return mappedMerch;
            }

            if (merch[`${merchName}Options`]) {
                const reducedMerchOptions = (
                    merch[`${merchName}Options`] as string[]
                ).reduce(
                    (merchOptions, merchOption) =>
                        `${merchOptions};${merchOption}`,
                );

                return `${mappedMerch}${merchName}:[${reducedMerchOptions}]|`;
            }

            const merchCount = merch[`${merchName}Count`];
            return `${mappedMerch}${merchName}:${merchCount}|`;
        }, '');

    return mappedMerch.slice(0, -1);
};
