import React, { ChangeEvent, HtmlHTMLAttributes, useContext } from 'react';
import { FieldType, SelectOption } from './types';
import { FormContext } from '../../signUp/components/SignUpForm';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';

type Props = {
    name: string;
    label?: string;
    fieldType: FieldType;
    isRequired: boolean;
    id?: string;
    cssStyles?: SerializedStyles;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    options?: SelectOption[];
};

const Field: React.FC<Props> = ({
    name,
    label,
    fieldType = 'Text',
    isRequired,
    id,
    cssStyles,
    value,
    onChange,
    options,
}) => {
    const { setValue, setValues, validate, touched, setTouched, errors } =
        useContext(FormContext);

    const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (setValue) {
            setValue(name, e.currentTarget.value);
        }
        if (touched[name]) {
            if (validate) {
                validate(name);
            }
        }
    };

    const onBlur = () => {
        if (setTouched) {
            setTouched(name);
        }
        if (validate) {
            validate(name);
        }
    };
    return (
        <FormContext.Consumer key={name}>
            {({ values }) => (
                <div key={name}>
                    {fieldType === 'Radio' && (
                        <React.Fragment>
                            <input
                                type="radio"
                                id={name}
                                name={id}
                                value={value}
                                onChange={onChange}
                            />
                            <label
                                css={[
                                    css`
                                        margin-left: 10px;
                                    `,
                                    cssStyles,
                                ]}
                                htmlFor={name}
                            >
                                {label}
                            </label>
                        </React.Fragment>
                    )}
                    {fieldType === 'Text' && (
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                margin-bottom: 15px;
                            `}
                        >
                            {label && (
                                <label
                                    css={css`
                                        display: block;
                                        font-size: 14px;
                                        color: #555555;
                                        margin-bottom: 5px;
                                        ${isRequired === true &&
                                        `&:after {
                                    content:" *";
                                `}
                                    `}
                                    htmlFor={name}
                                >
                                    {label}
                                </label>
                            )}
                            <input
                                css={css`
                                    line-height: 45px;
                                    box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px
                                        inset !important;
                                    color: #666666;
                                    background: #fafafa;
                                    border-radius: 5px;
                                    padding: 0px 20px;
                                    transition: all 0.4s ease 0s;
                                    font-size: 14px;
                                    border-style: none;
                                    outline: none;
                                `}
                                type="text"
                                autoComplete="false"
                                id={name}
                                name={id}
                                value={
                                    values[name] === undefined
                                        ? ''
                                        : (values[name] as string)
                                }
                                onChange={onValueChange}
                                onBlur={onBlur}
                            />
                        </div>
                    )}
                    {fieldType === 'Select' && (
                        <React.Fragment key={name}>
                            <select
                                css={css`
                                    margin-top: 15px;
                                    width: 150px;
                                    height: 35px;
                                    box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px
                                        inset !important;
                                    color: #666666;
                                    background: #fafafa;
                                    border-radius: 5px;
                                    padding: 0px 20px;
                                    transition: all 0.4s ease 0s;
                                    font-size: 14px;
                                    border-style: none;
                                    outline: none;
                                `}
                                name={name}
                                // onChange={onChange}
                            >
                                {options &&
                                    options.map((option) => (
                                        <option value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                            </select>
                        </React.Fragment>
                    )}
                    {errors[name] &&
                        errors[name].length > 0 &&
                        errors[name].map((error) => (
                            <div
                                key={error}
                                css={css`
                                    font-size: 12px;
                                    color: red;
                                    margin-bottom: 10px;
                                `}
                            >
                                {error}
                            </div>
                        ))}
                </div>
            )}
        </FormContext.Consumer>
    );
};

export default Field;
