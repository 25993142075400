import styled from '@emotion/styled';

export const SignupFormButton = styled.button`
    border-color: #326e99;
    background-color: #62761d;
    border: 0;
    color: #ffffff;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: all 200ms ease-in-out 0s;
    padding: 7px 25px;
`;

/*
<button
    onClick={handlePreviousButtonClicked}
    css={css`
        border-color: #326e99;
        background-color: #3f8abf;
        border: 0;
        color: #ffffff;
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        transition: all 200ms ease-in-out 0s;
        padding: 7px 25px;
    `}
    type="button"
    disabled={currentSectionIndex === 0}
>
    Wstecz
</button>;
*/

/*
<button
    onClick={handleNextOrSubmitButtonClicked}
    css={css`
        border-color: #326e99;
        background-color: #3f8abf;
        border: 0;
        color: #ffffff;
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        transition: all 200ms ease-in-out 0s;
        padding: 7px 25px;
    `}
    disabled={currentSectionIndex === 0 && !values['accreditationType']}
    type={currentSectionIndex === 2 ? 'submit' : 'button'}
>
    {currentSectionIndex === 2 ? 'Wyślij' : 'Dalej'}
</button>;
*/
