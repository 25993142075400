import React from 'react';
import { Link } from 'react-router-dom';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

/* eslint-disable @typescript-eslint/no-unused-expressions */
jsx;

const NavBar: React.FC = () => {
    return (
        <nav
            css={css`
                position: sticky;
                background-color: #62761d;
                padding: 0 5px;
                font-size: 16px;
                color: #f6f6f6;
                z-index: 20;
                line-height: 50px;
                display: flex;
                justify-content: space-between;
            `}
        >
            <span>nakonwent.pl - Imladris XXII 9-11.11.2024</span>
            {window.location.href.indexOf('/pl') === -1 &&
                window.location.href.indexOf('/en') === -1 && (
                    <a href="/en"></a>
                )}
            {window.location.href.indexOf('/pl') > -1 && <a href="/en"></a>}
            {window.location.href.indexOf('/en') > -1 && (
                <a href="/pl">Polish</a>
            )}
        </nav>
    );
};

export default NavBar;
