import React from 'react';
import { Link } from 'react-router-dom';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const typeTile = css`
    flex: 0 0 auto;
    margin: 30px 0px 30px 30px;
    height: 150px;
    width: 150px;
    background-color: #3399ff;
    border-radius: 5px;
    color: #fff;
    &:hover {
        height: 155px;
        width: 155px;
        margin-left: 30px;
        margin-right: 0px;
        margin-bottom: 25px;
        transition: 0.2s;
        background-color: #0059b3;
        border: 1px solid #001a33;
        cursor: pointer;
    }
`;

const typeTileText = css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    padding-top: 5px;
`;

type TypeIconSpanProps = {
    content: string;
};

const TypeIconSpan = styled.span<TypeIconSpanProps>`
    &::before {
        font-family: MyWebFont;
        color: #fff;
        font-size: 30px;
        content: '${({ content }) => `${content}`}';
    }
`;

const Participant: React.FC = () => {
    return (
        <div
            css={css`
                padding: 0px 15px;
                margin-left: auto;
                margin-right: auto;
                color: #292b2c; //to bedzie wspólny?
            `}
        >
            <div
                css={css`
                    text-align: center;
                `}
            >
                <p>Wybierz typ akredytacji:</p>
            </div>
            <div
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    width: 1080px;
                    margin: auto;
                    justify-content: center;
                `}
            >
                <div css={typeTile}>
                    <Link to="/signup">
                        <div css={typeTileText}>
                            <TypeIconSpan content={'\\e971'}></TypeIconSpan>
                            <p className="services-txt">Uczestnik</p>
                        </div>
                    </Link>
                </div>
                <div css={typeTile}>
                    <Link to="/signup">
                        <div css={typeTileText}>
                            <TypeIconSpan content={'\\e991'}></TypeIconSpan>
                            <p className="services-txt">Obsługa</p>
                        </div>
                    </Link>
                </div>
                <div css={typeTile}>
                    <Link to="/signup">
                        <div css={typeTileText}>
                            <TypeIconSpan content={'\\e9a5'}></TypeIconSpan>
                            <p className="services-txt">Program</p>
                        </div>
                    </Link>
                </div>
                <div css={typeTile}>
                    <Link to="/signup">
                        <div css={typeTileText}>
                            <TypeIconSpan content={'\\e93a'}></TypeIconSpan>
                            <p className="services-txt">Wystawca</p>
                        </div>
                    </Link>
                </div>
                <div css={typeTile}>
                    <Link to="/signup">
                        <div css={typeTileText}>
                            <TypeIconSpan content={'\\e914'}></TypeIconSpan>
                            <p className="services-txt">Media</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Participant;
