import React, { ChangeEvent, useContext } from 'react';
import { SelectOption } from './types';
import { FormContext, MerchOptions } from '../../signUp/components/SignUpForm';
import { required } from './validators/required';
import { ValidationErrors } from './validators/types';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

type Props = {
    name: string;
    id?: number;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    options?: SelectOption[];
};

const SelectField: React.FC<Props> = ({ name, onChange, id, options }) => {
    const { errors, setErrors, setValues, values } = useContext(FormContext);

    const onChangeFoo = (e: ChangeEvent<HTMLSelectElement>) => {
        const [optionName, , optionIndex] = e.target.name.split('_');

        const merch = values['Merch'] as MerchOptions;
        const merchOptions = merch[`${optionName}Options`] as string[];

        merchOptions.splice(Number(optionIndex), 1, e.target.value);

        if (setValues) {
            setValues({
                ...values,
                ['Merch']: merch,
            });
        }
    };

    const onBlur = (e: ChangeEvent<HTMLSelectElement>): void => {
        const requiredError = required(e.target.value);

        const newErrors = {
            ...errors,
            [e.target.name]: [requiredError],
        } as ValidationErrors;
        if (setErrors) {
            setErrors(newErrors);
        }
    };

    return (
        <FormContext.Consumer>
            {() => (
                <div>
                    <select
                        key={id}
                        css={css`
                            margin-top: 15px;
                            width: 150px;
                            height: 35px;
                            box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px inset !important;
                            color: #666666;
                            background: #fafafa;
                            border-radius: 5px;
                            padding: 0px 20px;
                            transition: all 0.4s ease 0s;
                            font-size: 14px;
                            border-style: none;
                            outline: none;
                        `}
                        name={name}
                        onChange={onChangeFoo}
                        onBlur={onBlur}
                    >
                        {options &&
                            options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                    </select>
                    <div
                        css={css`
                            text-align: center;
                        `}
                    >
                        {errors[name] &&
                            errors[name].length > 0 &&
                            errors[name].map((error) => (
                                <div
                                    key={error}
                                    css={css`
                                        font-size: 12px;
                                        color: red;
                                    `}
                                >
                                    {error}
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </FormContext.Consumer>
    );
};

export default SelectField;
