/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUpPaymentFinished: React.FC = () => {
    return (
        <div>
            <div
                css={css`
                    font-size: 20px;
                    text-align: center;
                    margin-top: 50px;
                    margin-bottom: 50px;
                `}
            >
                Płatność zakończona!
            </div>
            <div
                css={css`
                    font-size: 16px;
                    margin-left: 30px;
                    margin-right: 30px;
                `}
            >
                Jeśli płatność zakończyła się sukcesem, na podany adres
                otrzymasz email potwierdzający Twoje zamówienie. W przypadku
                problemów z płatnością prosimy o kontakt na adres:
                przedplaty@imladris.pl.
            </div>
        </div>
    );
};

export default SignUpPaymentFinished;
