import { MembershipType, MembershipForm } from '../types';
import { appSettings } from '../../AppSettings';

export const getMemberships = async (): Promise<MembershipType[]> => {
    const response = await fetch('https://localhost:44357/membership');

    const data = await response.json();

    return data as MembershipType[];
};

//TODO JK: make common call // remove union type
export const getMembershipByType = async (
    membershipType: string | undefined,
): Promise<MembershipForm> => {
    const response = await fetch(
        appSettings.backendUrl +
            '/membership/' +
            membershipType +
            '?language=' +
            appSettings.language,
        //'https://localhost:44357/membership/' + membershipType,
    );

    const data = await response.json();

    return data as MembershipForm;
};
