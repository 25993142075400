import React, { FC } from 'react';
import { useAuth } from './Auth';
type SignoutAction = 'signout' | 'signout-callback';
interface Props {
    action: SignoutAction;
}
export const SignOutPage: FC<Props> = ({ action }) => {
    let message = 'Signing out ...';
    const { signOut } = useAuth();
    switch (action) {
        case 'signout':
            signOut();
            break;
        case 'signout-callback':
            message = 'You successfully signed out!';
            break;
    }
    return <div>{message}</div>;
};
