/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUpTerms: React.FC = () => {
    return (
        <div>
            <div
                css={css`
                    font-size: 20px;
                    text-align: center;
                    margin-top: 40px;
                    margin-bottom: 40px;
                `}
            >
                Regulamin serwisu
            </div>
            <div
                css={css`
                    font-size: 16px;
                    margin-left: 30px;
                    margin-right: 30px;
                    line-height: 2;
                `}
            >
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Postanowienia ogólne:
                </p>
                1. Niniejszy regulamin określa zasady i warunki internetowej
                sprzedaży akredytacji na konwent XXII Imladris zwany dalej
                Konwentem, organizowany przez Fundację „Historia Vita” z
                siedzibą w Krakowie, nr KRS 0000452311 zwanej dalej
                “Organizatorem”, oraz gadżetów konwentowych.
                <br />
                2. Regulamin Konwentu dostępny jest na stronie internetowej:
                https://imladris.pl.
                <br />
                3. Każda osoba nabywająca akredytację i gadżety konwentowe
                (zwana dalej “Uczestnikiem”) zobowiązana jest do zapoznania się
                z treścią regulaminu przed dokonaniem zakupu. Zakup akredytacji
                lub gadżetu konwentowego jest równoznaczny z akceptacją
                postanowień regulaminu.
                <br />
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Zakup Akredytacji i Gadżetów Konwentowych:
                </p>
                1. Akredytacje na Konwent oraz gadżety konwentowe (m.in.
                koszulki, bluzy, torby) są dostępne wyłącznie za pośrednictwem
                strony internetowej https://nakonwent.pl, zwanej dalej
                “Serwisem”. <br />
                2. Aby dokonać zakupu akredytacji i/lub gadżetów konwentowych,
                Klient musi:
                <br />
                &emsp;&emsp; a. Wybrać odpowiedni rodzaj akredytacji. <br />
                &emsp;&emsp; b. Wybrać odpowiedni produkt i ilość (w przypadku
                zakupu gadżetów konwentowych). <br />
                &emsp;&emsp; c. Wprowadzić wymagane dane osobowe, takie jak
                imię, nazwisko, adres poczty elektronicznej. <br />
                &emsp;&emsp; d. Dokonać płatności za pomocą platformy
                Przelewy24. <br />
                3. Po pomyślnym dokonaniu płatności, dokument akredytacyjny
                zostanie przesłany na adres poczty elektronicznej podany przez
                Klienta w formacie PDF, gotowy do wydruku lub do okazania na
                urządzeniu mobilnym przy wejściu na teren Konwentu. Gadżety będą
                do odbioru w specjalnie oznaczonym punkcie na terenie Konwentu,
                po okazaniu dokumentu akredytacyjnego.
                <br />
                4. Uczestnik zobowiązany jest do sprawdzenia poprawności danych
                zawartych na dokumencie akredytacyjnym. Wszelkie niezgodności
                należy niezwłocznie zgłosić Fundacji. <br />
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Zwroty i Reklamacje:
                </p>
                1. Zakupione w Serwisie akredytacje nie podlegają zwrotowi ani
                wymianie zgodnie z art. 38 pkt 12 ustawy z dnia 30 maja 2014
                roku o prawach konsumenta (t.j. Dz.U. z 2020 r. poz. 287, ze
                zm.). <br />
                2. W przypadku odwołania konwentu Uczestnikowi przysługuje zwrot
                pełnej kwoty za bilet. Procedura zwrotu zostanie określona na
                stronie internetowej Konwentu. <br />
                3. W przypadku zakupu gadżetów konwentowych, w ciągu 14 dni od
                otrzymania produktów, Uczestnik przysługuje prawo do odstąpienia
                od umowy bez podania przyczyny. Uczestnik może skorzystać z tego
                prawa, powiadamiając Serwis poprzez wiadomość e-mail wysłaną na
                adres przedplaty@imladris.pl. <br />
                4. Po odstąpieniu Klient ma obowiązek niezwłocznie, nie później
                niż w terminie 14 dni od dnia, w którym odstąpił od umowy,
                zwrócić zamówione gadżety konwentowe na adres Organizatora: ul.
                Os. Akademickie 6/22, 31-866 Kraków. <br />
                5. Reklamacje dotyczące biletów oraz gadżetów kowentowych można
                składać drogą elektroniczną na adres przedplaty@imladris.pl.
                Serwis rozpatrzy reklamację w ciągu 30 dni od jej otrzymania.{' '}
                <br />
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Postanowienia końcowe:
                </p>
                1. Organizator zastrzega sobie prawo do zmiany niniejszego
                regulaminu. Wszelkie zmiany będą publikowane na stronie
                internetowej konwentu i wchodzą w życie z dniem ich
                opublikowania. <br />
                2. W sprawach nieuregulowanych niniejszym regulaminem stosuje
                się przepisy prawa polskiego. <br />
            </div>
        </div>
    );
};

export default SignUpTerms;
