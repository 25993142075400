import React, { useEffect } from 'react';
import { useAuth } from './Auth';

type SigninAction = 'signin' | 'signin-callback';

type Props = {
    action: SigninAction;
};

export const SignInPage: React.FC<Props> = ({ action }) => {
    const { clientLoaded, signIn } = useAuth();

    useEffect(() => {
        if (action === 'signin' && clientLoaded) {
            signIn();
        }
    }, [clientLoaded, signIn, action]);

    return (
        <div>
            <div>Signing in...</div>
        </div>
    );
};
