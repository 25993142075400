/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUpFinished: React.FC = () => {
    return (
        <div>
            <div
                css={css`
                    font-size: 20px;
                    text-align: center;
                    margin-top: 50px;
                    margin-bottom: 50px;
                `}
            >
                Rejestracja zakończona!
            </div>
            <div
                css={css`
                    font-size: 16px;
                    margin-left: 30px;
                    margin-right: 30px;
                `}
            >
                Gdy Twoja akredytacja zostanie potwierdzona, na podany adres
                otrzymasz email zawierający dokument z kodem QR, który należy
                okazać przy wejściu na konwent. W przypadku pytań prosimy o
                kontakt na adres: przedplaty@imladris.pl.
            </div>
        </div>
    );
};

export default SignUpFinished;
