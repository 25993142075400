type AppSettings = {
    [key: string]: string;
};

export const authSettings = {
    domain: 'nakonwent-pl.eu.auth0.com',
    client_id: 'YK7LWMyKI3wOzlCJaiMmPBpRIc22zoly',
    redirect_uri: window.location.origin + '/signin-callback',
    scope: 'openid profile api email',
    audience: 'https://backend.nakonwent.pl',
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const merchEnabled = 'Y';
const photoPath = '/photos';

export const appSettings = {
    ...authSettings,
    backendUrl,
    merchEnabled,
    photoPath,
} as AppSettings;
