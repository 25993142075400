import React, { useContext, MouseEvent } from 'react';
import { SignupFormButton } from './styled';
//import { FormContext } from './../Core/Form/Form';
import { FormContext } from './SignUpForm';
import { useTranslation } from 'react-i18next';
import { appSettings } from '../../AppSettings';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
// import styled from '@emotion/styled';

const SignUpFormFooter: React.FC = () => {
    const { t } = useTranslation();

    const lang = appSettings.language;

    const {
        currentSection,
        values,
        setCurrentSection,
        currentAccreditationType,
        currentPrice,
        sectionsNumber,
    } = useContext(FormContext);

    const handlePreviousButtonClicked = (
        e: MouseEvent<HTMLButtonElement>,
    ): void => {
        if (setCurrentSection) {
            if (currentSection === 2) {
                setCurrentSection(currentSection - 1);
            } else {
                setCurrentSection(currentSection - 1);
            }
            e.preventDefault();
        }
    };

    const handleNextOrSubmitButtonClicked = (
        e: MouseEvent<HTMLButtonElement>,
    ): void => {
        if (currentSection !== sectionsNumber && setCurrentSection) {
            setCurrentSection(currentSection + 1);
            e.preventDefault();
        }

        /*
        if (
            (currentAccreditationType === 'Teenager' && currentSection !== 2) ||
            (currentAccreditationType !== 'Teenager' && currentSection !== 3)
        ) {
            if (setCurrentSection && currentSection === 0) {
                setCurrentSection(currentSection + 1);
            } else {
                if (setCurrentSection) {
                    setCurrentSection(currentSection + 1);
                }
            }
            e.preventDefault();
        }
        */
    };

    return (
        <div>
            <div
                css={css`
                    text-align: right;
                    padding-bottom: 20px;
                    font-size: 16px;
                `}
            >
                {t('signup.form.total-price')}:{' '}
                <strong>
                    {currentPrice} {lang === 'pl' ? ' zł' : ' EUR'}
                </strong>
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <SignupFormButton
                    onClick={handlePreviousButtonClicked}
                    disabled={currentSection === 0}
                >
                    {t('signup.form.button-previous')}
                </SignupFormButton>
                <SignupFormButton
                    onClick={handleNextOrSubmitButtonClicked}
                    disabled={currentSection === 0 && !currentAccreditationType}
                    type={
                        currentSection === sectionsNumber &&
                        sectionsNumber !== 0
                            ? 'submit'
                            : 'button'
                    }
                >
                    {currentSection === sectionsNumber && sectionsNumber !== 0
                        ? t('signup.form.button-submit')
                        : t('signup.form.button-next')}
                </SignupFormButton>
            </div>
        </div>
    );
};

export default SignUpFormFooter;
