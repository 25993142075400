import React, {
    useEffect,
    useState,
    useContext,
    Fragment,
    ChangeEvent,
} from 'react';
import { MembershipFormSection, SignUpFormMerchOption } from '../types';
import { SelectOption } from '../../Core/Form/types';
import { FormContext, MerchOptions } from './SignUpForm';
import Field from '../../Core/Form/Field';
import SelectField from '../../Core/Form/SelectField';
import MembershipLink from './MembershipLink';
import Spinner from '../../Core/components/Spinner';
import { Values } from './SignUpForm';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

type Props = {
    section: MembershipFormSection;
    sectionNumber: number;
    accreditationType: string;
};

type SectionMerch = {
    [name: string]: SectionMerchItem;
};

type SectionMerchItem = {
    price: number;
    hasOptions: boolean;
};

const SignUpFormSection: React.FC<Props> = ({
    section,
    sectionNumber,
    accreditationType,
}) => {
    const {
        currentSection,
        currentAccreditationType,
        validate,
        values,
        setValues,
        currentPrice,
        setCurrentPrice,
        isTermsError,
        setIsTermsError,
        isAddonError,
    } = useContext(FormContext);

    const merch = values['Merch'] as MerchOptions;
    const [checked, setChecked] = useState(false);

    let sectionMerch = {} as SectionMerch;

    if (section.typeID === 1) {
        section.merch.map(
            (m) =>
                (sectionMerch[m.name] = {
                    price: m.price,
                    hasOptions: m.options?.length > 0,
                }),
        );
    }

    const onMerchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const merch = values['Merch'] as MerchOptions;
        const name = e.target.name;

        const merchOptions = merch[name];

        if (merchOptions === undefined) {
            merch[`${name}Count`] = 1;

            if (sectionMerch[name].hasOptions) {
                merch[`${name}Options`] = [''];
            }
        }

        merch[name] = e.target.checked ? 1 : 0;

        const merchCount = Number(merch[`${name}Count`]);

        let newPrice = 0;

        newPrice = e.target.checked
            ? currentPrice + merchCount * sectionMerch[name].price
            : currentPrice - merchCount * sectionMerch[name].price;

        if (setValues) {
            setValues({
                ...values,
                ['Merch']: merch,
            });
        }

        if (setCurrentPrice) {
            setCurrentPrice(newPrice);
        }
    };

    const onMerchCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        const merchName = e.target.name.slice(0, e.target.name.length - 5);

        let value = e.target.value;

        if (!value) {
            value = '1';
        } else {
            value = value.substring(value.length - 1, value.length);
        }

        if (Number(value) < 1) {
            value = '1';
        }

        if (Number(value) > 5) {
            value = '5';
        }

        if (e.target.name === 'CupCount' && Number(value) > 2) {
            value = '2';
        }

        const newMerchCount = Number(value);

        const merchOptions = merch[merchName + 'Options'];

        if (Array.isArray(merchOptions)) {
            if (merchOptions.length < newMerchCount) {
                while (merchOptions.length < newMerchCount) {
                    merchOptions.push('');
                }
            } else {
                merchOptions.splice(newMerchCount);
            }
        }

        const lastNameCount = merch[e.target.name];

        merch[e.target.name] = newMerchCount;

        //TODO JK: tutaj chyba zmiana nazw propertisa
        let priceWithNoTShirt =
            currentPrice -
            Number(lastNameCount) * sectionMerch[merchName].price;
        const newPrice =
            priceWithNoTShirt +
            Number(newMerchCount) * sectionMerch[merchName].price;

        if (setCurrentPrice) {
            setCurrentPrice(newPrice);
        }
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (setValues) {
            setValues({
                ...values,
                [e.target.name]: e.target.value,
            });
        }
    };

    const onBlurTextArea = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        if (validate) {
            validate(e.target.name);
        }
    };

    const onCheckboxChange = () => {
        const checkboxState = !checked;

        if (setValues) {
            setValues({
                ...values,
                Terms: checkboxState ? 1 : 0,
            });
        }
        setChecked(!checked);
    };

    const onCheckboxBlur = () => {
        if (setIsTermsError) {
            setIsTermsError(checked ? false : true);
        }
    };

    //service?
    const mapMerchOptionsToSelectOptions = (
        merchOptions: SignUpFormMerchOption[],
    ): SelectOption[] => {
        const firstOption = {
            value: '',
            label: '<Wybierz>',
        } as SelectOption;

        const options = merchOptions.map(
            (mi) =>
                ({
                    value: mi.name,
                    label: mi.label,
                } as SelectOption),
        );

        return [firstOption, ...options];
    };

    const getMerchImageWidth = (imageName: string): number => {
        if (imageName.startsWith('koszulka')) {
            return 275;
        }

        if (imageName.startsWith('bluza')) {
            return 300;
        }

        if (
            imageName.startsWith('torba') ||
            imageName.startsWith('naklejka') ||
            imageName.startsWith('zakładka')
        ) {
            return 200;
        }

        return 175;
    };

    return (
        <div
            style={{
                display:
                    sectionNumber === currentSection &&
                    accreditationType === currentAccreditationType
                        ? 'block'
                        : 'none',
            }}
        >
            <p
                css={css`
                    text-align: center;
                    font-size: 16px;
                `}
            >
                {section.title}
            </p>
            {section.title.startsWith('Dodatkowe Gadżety') && (
                <p
                    css={css`
                        text-align: center;
                    `}
                >
                    Zdjęcia poglądowe mogą nieznacznie różnić się od wersji
                    finalnych.
                </p>
            )}
            <fieldset>
                {section.typeID === 1 && (
                    <div>
                        {section.merch.map((merchItem, index) => (
                            <div key={index}>
                                <input
                                    type="checkbox"
                                    name={merchItem.name}
                                    value={merchItem.price}
                                    onChange={onMerchChange}
                                    checked={merch[merchItem.name] === 1}
                                />
                                <label
                                    css={css`
                                        font-size: 16px;
                                        margin-left: 10px;
                                        font-weight: bold;
                                    `}
                                    htmlFor="1"
                                >
                                    {merchItem.title} - {merchItem.price} zł
                                </label>
                                <p>
                                    {merchItem.description}
                                    {merchItem.name.startsWith('TShirt') && (
                                        <React.Fragment>
                                            <br />
                                            <strong>
                                                <a
                                                    href="/photos/rozmiarowka_meska.jpg"
                                                    target="_blank"
                                                >
                                                    (Rozmiarówka męska
                                                </a>
                                            </strong>
                                            ,{' '}
                                            <strong>
                                                <a
                                                    href="/photos/rozmiarowka_damska.jpg"
                                                    target="_blank"
                                                >
                                                    rozmiarówka damska)
                                                </a>
                                            </strong>
                                        </React.Fragment>
                                    )}
                                    {merchItem.name === 'Hoodie' && (
                                        <React.Fragment>
                                            <br />
                                            <strong>
                                                <a
                                                    href="/photos/bluza-rozmiarowka.jpg"
                                                    target="_blank"
                                                >
                                                    (Rozmiarówka)
                                                </a>
                                            </strong>
                                        </React.Fragment>
                                    )}
                                </p>
                                {merch[merchItem.name] === 1 &&
                                    merchItem.name === 'TShirt' && (
                                        <div
                                            css={css`
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    margin-bottom: 20px;
                                                `}
                                            >
                                                <input
                                                    type="number"
                                                    name={
                                                        merchItem.name + 'Count'
                                                    }
                                                    value={
                                                        merch[
                                                            merchItem.name +
                                                                'Count'
                                                        ] as string
                                                    }
                                                    //onBlur={onTShirtCountBlur}
                                                    onChange={
                                                        onMerchCountChange
                                                    }
                                                />
                                                <label
                                                    css={css`
                                                        font-size: 14px;
                                                        margin-left: 10px;
                                                        font-weight: bold;
                                                    `}
                                                    htmlFor="1"
                                                >
                                                    {'pl' === 'pl'
                                                        ? 'Liczba zamawianych koszulek (maks. 5 szt.)'
                                                        : 'Tshirts count'}
                                                </label>
                                            </div>
                                            {merchItem.options && (
                                                <div>
                                                    <label
                                                        css={css`
                                                            font-size: 14px;
                                                            margin-left: 10px;
                                                            font-weight: bold;
                                                            margin-bottom: 5px;
                                                            &:after {
                                                                content: ' *';
                                                            }
                                                        `}
                                                        htmlFor="1"
                                                    >
                                                        {'pl' === 'pl'
                                                            ? 'Rozmiar i krój (damska/męska) zamawianych koszulek'
                                                            : 'Size, color and type of ordered Tshirts'}
                                                    </label>
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            flex-wrap: wrap;
                                                            column-gap: 10px;
                                                        `}
                                                    >
                                                        {Array(
                                                            merch[
                                                                merchItem.name +
                                                                    'Count'
                                                            ],
                                                        )
                                                            .fill(0)
                                                            .map((_, index) => (
                                                                <SelectField
                                                                    key={index}
                                                                    id={index}
                                                                    name={
                                                                        merchItem.name +
                                                                        '_option_' +
                                                                        index
                                                                    }
                                                                    options={mapMerchOptionsToSelectOptions(
                                                                        merchItem.options,
                                                                    )}
                                                                ></SelectField>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                {merch[merchItem.name] === 1 &&
                                    merchItem.name === 'TShirtVip' && (
                                        <div
                                            css={css`
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    margin-bottom: 20px;
                                                `}
                                            >
                                                <input
                                                    type="number"
                                                    name={
                                                        merchItem.name + 'Count'
                                                    }
                                                    value={
                                                        merch[
                                                            merchItem.name +
                                                                'Count'
                                                        ] as string
                                                    }
                                                    //onBlur={onTShirtCountBlur}
                                                    onChange={
                                                        onMerchCountChange
                                                    }
                                                />
                                                <label
                                                    css={css`
                                                        font-size: 14px;
                                                        margin-left: 10px;
                                                        font-weight: bold;
                                                    `}
                                                    htmlFor="1"
                                                >
                                                    {'pl' === 'pl'
                                                        ? 'Liczba zamawianych koszulek (maks. 5 szt.)'
                                                        : 'Tshirts count'}
                                                </label>
                                            </div>
                                            {merchItem.options && (
                                                <div>
                                                    <label
                                                        css={css`
                                                            font-size: 14px;
                                                            margin-left: 10px;
                                                            font-weight: bold;
                                                            margin-bottom: 5px;
                                                            &:after {
                                                                content: ' *';
                                                            }
                                                        `}
                                                        htmlFor="1"
                                                    >
                                                        {'pl' === 'pl'
                                                            ? "Rozmiar zamawianych longsleeve'ów"
                                                            : 'Size, color and type of ordered Tshirts'}
                                                    </label>
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            flex-wrap: wrap;
                                                            column-gap: 10px;
                                                        `}
                                                    >
                                                        {Array(
                                                            merch[
                                                                merchItem.name +
                                                                    'Count'
                                                            ],
                                                        )
                                                            .fill(0)
                                                            .map((_, index) => (
                                                                <SelectField
                                                                    key={index}
                                                                    id={index}
                                                                    name={
                                                                        merchItem.name +
                                                                        '_option_' +
                                                                        index
                                                                    }
                                                                    options={mapMerchOptionsToSelectOptions(
                                                                        merchItem.options,
                                                                    )}
                                                                ></SelectField>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                {merch[merchItem.name] === 1 &&
                                    merchItem.name === 'Hoodie' && (
                                        <div
                                            css={css`
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    margin-bottom: 20px;
                                                `}
                                            >
                                                <input
                                                    type="number"
                                                    name={
                                                        merchItem.name + 'Count'
                                                    }
                                                    value={
                                                        merch[
                                                            merchItem.name +
                                                                'Count'
                                                        ] as string
                                                    }
                                                    //onBlur={onTShirtCountBlur}
                                                    onChange={
                                                        onMerchCountChange
                                                    }
                                                />
                                                <label
                                                    css={css`
                                                        font-size: 14px;
                                                        margin-left: 10px;
                                                        font-weight: bold;
                                                    `}
                                                    htmlFor="1"
                                                >
                                                    {'pl' === 'pl'
                                                        ? 'Liczba zamawianych bluz'
                                                        : 'Tshirts count'}
                                                </label>
                                            </div>
                                            {merchItem.options && (
                                                <div>
                                                    <label
                                                        css={css`
                                                            font-size: 14px;
                                                            margin-left: 10px;
                                                            font-weight: bold;
                                                            margin-bottom: 5px;
                                                            &:after {
                                                                content: ' *';
                                                            }
                                                        `}
                                                        htmlFor="1"
                                                    >
                                                        {'pl' === 'pl'
                                                            ? 'Rozmiar zamawianych bluz'
                                                            : 'Size, color and type of ordered Tshirts'}
                                                    </label>
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            flex-wrap: wrap;
                                                            column-gap: 10px;
                                                        `}
                                                    >
                                                        {Array(
                                                            merch[
                                                                merchItem.name +
                                                                    'Count'
                                                            ],
                                                        )
                                                            .fill(0)
                                                            .map((_, index) => (
                                                                <SelectField
                                                                    key={index}
                                                                    id={index}
                                                                    name={
                                                                        merchItem.name +
                                                                        '_option_' +
                                                                        index
                                                                    }
                                                                    options={mapMerchOptionsToSelectOptions(
                                                                        merchItem.options,
                                                                    )}
                                                                ></SelectField>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                {values[merchItem.name] === 1 &&
                                    merchItem.name === 'Bag' && (
                                        <div
                                            css={css`
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    margin-bottom: 20px;
                                                `}
                                            >
                                                <input
                                                    type="number"
                                                    name={
                                                        merchItem.name + 'Count'
                                                    }
                                                    value={
                                                        values[
                                                            merchItem.name +
                                                                'Count'
                                                        ] as string
                                                    }
                                                    onChange={
                                                        onMerchCountChange
                                                    }
                                                />
                                                <label
                                                    css={css`
                                                        font-size: 14px;
                                                        margin-left: 10px;
                                                        font-weight: bold;
                                                    `}
                                                    htmlFor="1"
                                                >
                                                    {'pl' === 'pl'
                                                        ? 'Liczba zamawianych toreb (maks. 5 szt.)'
                                                        : 'Bags count'}
                                                </label>
                                            </div>
                                            {merchItem.options && (
                                                <div>
                                                    <label
                                                        css={css`
                                                            font-size: 14px;
                                                            margin-left: 10px;
                                                            font-weight: bold;
                                                            margin-bottom: 5px;
                                                            &:after {
                                                                content: ' *';
                                                            }
                                                        `}
                                                        htmlFor="1"
                                                    >
                                                        {'pl' === 'pl'
                                                            ? 'Typ zamawianych czapek'
                                                            : 'Size, color and type of ordered Tshirts'}
                                                    </label>
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            flex-wrap: wrap;
                                                            column-gap: 10px;
                                                        `}
                                                    >
                                                        {Array(
                                                            merch[
                                                                merchItem.name +
                                                                    'Count'
                                                            ],
                                                        )
                                                            .fill(0)
                                                            .map((_, index) => (
                                                                <SelectField
                                                                    key={index}
                                                                    id={index}
                                                                    name={
                                                                        merchItem.name +
                                                                        '_option_' +
                                                                        index
                                                                    }
                                                                    options={mapMerchOptionsToSelectOptions(
                                                                        merchItem.options,
                                                                    )}
                                                                ></SelectField>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                {merch[merchItem.name] === 1 &&
                                    merchItem.name === 'Cup' && (
                                        <div
                                            css={css`
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    margin-bottom: 20px;
                                                `}
                                            >
                                                <input
                                                    type="number"
                                                    name={
                                                        merchItem.name + 'Count'
                                                    }
                                                    value={
                                                        merch[
                                                            merchItem.name +
                                                                'Count'
                                                        ] as string
                                                    }
                                                    onChange={
                                                        onMerchCountChange
                                                    }
                                                />
                                                <label
                                                    css={css`
                                                        font-size: 14px;
                                                        margin-left: 10px;
                                                        font-weight: bold;
                                                    `}
                                                    htmlFor="1"
                                                >
                                                    {'pl' === 'pl'
                                                        ? 'Liczba zamawianych kubków (maks. 2 szt.)'
                                                        : 'Bags count'}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                <div
                                    className="click-zoom"
                                    css={css`
                                        margin-bottom: 20px;
                                    `}
                                >
                                    {merchItem.images.map((image, index) => (
                                        <img
                                            css={css`
                                                margin-right: 20px;
                                            `}
                                            key={index}
                                            src={'/photos/' + image.fileName}
                                            width={getMerchImageWidth(
                                                image.fileName,
                                            )}
                                            height={
                                                image.fileName.startsWith(
                                                    'torba',
                                                )
                                                    ? '275'
                                                    : '200'
                                            }
                                            alt={image.fileName}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {section.typeID === 2 &&
                    section.fields.map((field, index) => (
                        <React.Fragment key={index}>
                            <Field
                                fieldType="Text"
                                name={field.name}
                                label={field.title}
                                isRequired={field.isRequired}
                                id={field.id}
                            ></Field>
                        </React.Fragment>
                    ))}
                {section.title
                    .toLowerCase()
                    .startsWith('dodatkowe informacje') && (
                    <div
                        css={css`
                            margin-top: 0px;
                        `}
                    >
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={onCheckboxChange}
                                onBlur={onCheckboxBlur}
                            />
                            <span
                                css={css`
                                    padding-left: 5px;
                                `}
                            ></span>
                            Akceptuję{' '}
                            <a
                                href="https://www.imladris.pl/okonwencie/regulamin/"
                                target="_blank"
                            >
                                <strong>regulamin</strong>
                            </a>{' '}
                            i{' '}
                            <a href="/privacyPolicy" target="_blank">
                                <strong>politykę prywatności</strong>
                            </a>
                        </label>
                        {isTermsError && (
                            <div
                                css={css`
                                    font-size: 12px;
                                    color: red;
                                    margin-bottom: 10px;
                                    margin-top: 5px;
                                `}
                            >
                                Musisz zaakceptować regulamin i politykę
                                prywatności.
                            </div>
                        )}
                    </div>
                )}
                {section.title.startsWith('Dane do wysyłki') && (
                    <div
                        css={css`
                            margin-top: 0px;
                        `}
                    >
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={onCheckboxChange}
                                onBlur={onCheckboxBlur}
                            />
                            <span
                                css={css`
                                    padding-left: 5px;
                                `}
                            ></span>
                            Akceptuję{' '}
                            <a
                                href="https://www.imladris.pl/okonwencie/regulamin/"
                                target="_blank"
                            >
                                <strong>regulamin</strong>
                            </a>{' '}
                            i{' '}
                            <a href="/privacyPolicy" target="_blank">
                                <strong>politykę prywatności</strong>
                            </a>
                        </label>
                        {isTermsError && (
                            <div
                                css={css`
                                    font-size: 12px;
                                    color: red;
                                    margin-bottom: 10px;
                                    margin-top: 5px;
                                `}
                            >
                                Musisz zaakceptować regulamin i politykę
                                prywatności.
                            </div>
                        )}
                        {isAddonError && (
                            <div
                                css={css`
                                    font-size: 12px;
                                    color: red;
                                    margin-bottom: 10px;
                                    margin-top: 5px;
                                `}
                            >
                                Musisz wybrać przynajmniej jeden gadżet.
                            </div>
                        )}
                    </div>
                )}
            </fieldset>
        </div>
    );
};

export default SignUpFormSection;
